.text {
  color: gray;
  font-size: 14px;
  margin-left: 10px;
  text-align: center;
  font-family: "Arial";
}

.text-sucess {
  color: green;
  font-weight: bold;
  font-size: 11px;
  margin-left: 10px;
  text-align: center;
}

.text-error {
  color: red;
  font-weight: bold;
  font-size: 11px;
  margin-left: 10px;
  text-align: center;
}

.text-aviso {
  color: black;
  font-weight: bold;
  font-size: 13px;
  margin-left: 10px;
  text-align: center;
}
