.div-coluna {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.logo-topo img {
  width: 350px;
  align-self: center;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img {
  width: 550px;
  padding-right: 100px;
}

.container-right {
  background-color: #27645e;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 2%;
  box-shadow: 5px 10px 8px #888888;
}

.container-right .termos {
  color: white;
  font-family: "Arial";
  font-size: 14px;
  align-items: center;
  padding-top: 2%;
}

.container-right p {
  width: 100%;
  color: white;
  font-size: 12px;
  color: #ed842b;
  height: 10px;
  padding-bottom: 3px;
}

.container-right button:hover {
  background-color: white;
  cursor: pointer;
  font-family: "Arial";
}

.titulo {
  text-align: center;
  font-size: 18px;
  margin-bottom: 14px;
  margin-top: 14px;
  color: #b2d556;
  font-weight: bold;
  font-family: "Arial";
}

.subtitulo {
  font-size: 14px;
  margin-bottom: 2px;
  color: white;
  font-weight: bold;
  font-family: "Arial";
}

.subtitulo-inscricao {
  font-size: 14px;
  margin-bottom: 2px;
  color: white;
  font-weight: bold;
  font-family: "Arial";
  text-align: center;
}

.div-subtitulo {
  font-size: 14px;
  margin-bottom: 10px;
  padding-left: 25px;
  color: white;
  font-weight: bold;
  font-family: "Arial";
  align-self: start !important;
}

.input-label {
  height: 20px;
  color: white;
  font-family: "Arial";
  font-size: 14px;
}

.div-linha {
  flex-direction: row;
  display: flex;
  background-color: white;
  align-items: center;
  border-radius: 3px;
  width: 100%;
  padding-top: 2.2px;
  padding-bottom: 2.2px;
  height: 30px;
  border-radius: 5px;
}

.labelcheckbox {
  padding-left: 10px;
  font-size: 14px;
}

.option-radio {
  color: gray !important;
  font-size: 14px !important;
  padding-left: 20px;
}

.option-radio-selected {
  color: gray !important;
  font-size: 14px !important;
  padding-left: 20px;
  font-weight: bold !important;
}

.radio {
  display: flex;
  flex-direction: row;
  color: white;
  font-family: "Arial";
  font-size: 14px;
  padding-right: 3%;
}

input[type="radio"]:after {
  width: 14px;
  height: 14px;
  border-radius: 15px;
  background-color: white;
  content: "";
  position: relative;
  bottom: 2px;
  right: 1px;
  display: inline-block;
  visibility: visible;
  border: 3px solid gray;
}

input[type="radio"]:checked:after {
  width: 14px;
  height: 14px;
  border-radius: 15px;
  background-color: #b2d556;
  content: "";
  position: relative;
  bottom: 2px;
  right: 1px;
  display: inline-block;
  visibility: visible;
  border: 3px solid gray;
}

input[type="checkbox"]:checked:after {
  width: 15px;
  height: 15px;
  background-color: #b2d556;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid gray;
}

.link {
  color: #b2d556;
  font-weight: bold;
}

.container-input {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 3px;
  width: 100%;
  padding-top: 2.2px;
  padding-bottom: 2.2px;
  height: 30px;
  border-radius: 5px;
}

.container-input2 {
  display: flex;
  align-items: center;
  background-color: white;
  color: #888888;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  width: 6%;
  padding-top: 2.2px;
  padding-bottom: 2.2px;
  height: 33.7px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  justify-content: center;
  font-size: 20px;
}


.container-dropzone {
  display: flex;
  width: 100%;
}

.container-input svg {
  margin-left: 10px;
  font-size: 22px;
  color: gray;
}

.termos svg {
  font-size: 18px;
  color: white;
  position: relative;
  top: 5px;
}

.div-linha svg {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 18px;
  color: gray;
}

.container-input input {
  background: transparent;
  width: 100%;
  outline-width: 0;
  border: none;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  color: gray;
  font-family: "Arial";
}

.recaptcha {
  display: flex;
  align-self: center;
  width: 100%;
  padding-top: 1%;
  padding-bottom: 2%;
  justify-content: center;
}

.div-buttons {
  margin-top: 10px;
  margin-bottom: 1%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.div-buttons-voltar {
  margin-top: 10px;
  align-self: flex-start !important;
  padding-left: 20px;
}

.div-buttons-voltar button {
  width: 80px;
  background-color: #b2d556;
  font-weight: 600;
  color: #27645e;
  border-radius: 5px;
  font-size: 16px;
  border: none;
  font-family: "Arial";
  outline-width: 0;
  padding: 0.5%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.div-margin {
  margin-bottom: 10px;
}

.div-buttons button {
  width: 25%;
  background-color: #b2d556;
  font-weight: 600;
  color: #27645e;
  border-radius: 5px;
  font-size: 18px;
  border: none;
  font-family: "Arial";
  outline-width: 0;
  padding: 0.5%;
}

@media (max-width: 1500px) {
  .div-coluna {
    height: 100%;
  }
  .container-right {
    width: 95%;
  }
  .logo-topo img {
    display: flex;
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .logo img {
    display: none;
  }
}

@media (min-width: 1500px) {
  .div-coluna {
    height: 100vh;
  }
  .logo-topo img {
    display: none;
  }
  .container-right {
    width: 800px;
  }
  .logo img {
    display: flex;
  }
}

.grecaptcha-badge { 
  visibility: hidden; 
}

.div-video { 
  display: none; 
}

.rodape-recaptcha { 
  align-items: flex-start !important;
  color: white;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 20px;
  align-self: flex-start !important;
  font-family: "Arial";
  font-size: 14px;
}

.input-select {
  width: 100%;
}

.teste{
  display: flex;
  flex-direction: row;
}